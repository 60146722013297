// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-event-js": () => import("/opt/build/repo/src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-track-js": () => import("/opt/build/repo/src/templates/track.js" /* webpackChunkName: "component---src-templates-track-js" */),
  "component---src-templates-run-js": () => import("/opt/build/repo/src/templates/run.js" /* webpackChunkName: "component---src-templates-run-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-events-js": () => import("/opt/build/repo/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-runs-js": () => import("/opt/build/repo/src/pages/runs.js" /* webpackChunkName: "component---src-pages-runs-js" */)
}

